.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0 0 5px #555);
}

.arrow:hover {
    cursor: pointer;
}

.indicators {
    display: flex;
    margin: 2rem;

}

.indicator-current {
    background-color: white;
    border: none;
    outline: none;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    box-shadow: 0 0 5px #555;
    margin: 0.2rem;
    cursor: pointer;
}

.indicator {
    background-color: #797676;
    border: none;
    outline: none;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    box-shadow: 0 0 5px #555;
    margin: 0.2rem;
    cursor: pointer;
}

.carousel-card {
    border: none;
    box-shadow: 0 0 5px var(--dark-color);
}

.card-image {
    width: calc(25vw);
    height: calc(16.41vw);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.carousel-link {
    text-decoration: none;
    color: var(--dark-color);
    font-weight: bold;
    border-radius: 10px;
    border: solid 2px var(--gold-color);
    padding: 0.5rem;
    margin: 4rem;
}