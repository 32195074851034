.showcase h1 {
    color: var(--gold-color);
    font-weight: bold;
    font-family: "Playball", cursive;
    font-size: calc(9.6vw);
    text-shadow: 3px 3px 0 var(--light-color), 6px 6px 0 var(--dark-color);
    z-index: 1;
}

.showcase:before {
    content: "";
    background: rgba(8, 8, 8, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.showcase {
    background: url("../../img/showcase.JPG") no-repeat center center/cover;
    position: relative;
    padding: 12%;
    height: calc(36vw);
    width: 100%;
    z-index: -1;
}

.showcase p {
    color: var(--light-color);
    z-index: 1;
    font-size: calc(1.5vw);
}

.showcase h1 span {
    color: var(--light-color);
    font-size: calc(5.5vw);
    text-shadow: 2px 2px var(--gold-color);
}