.footer {
    background-color: var(--light-grey);
    padding: 1rem;
    border-top: solid 1px var(--gold-color);
}

.footer-nav a {
    color: white;
    padding: 0.75rem;
    text-decoration: none;
    margin-right: 1rem;
    font-weight: bold;
}

.footer-current {
    border-bottom: solid 1px var(--dark-color);
}